<template>
    <div class="row justify-content-center" v-if="loading">
        <div class="col-sm-5 text-center pt-2">
            <font-awesome-icon icon="spinner" spin size="3x"></font-awesome-icon>
            <p class="text-center my-1">Loading...</p>
        </div>
    </div>
    <div v-else>
        <div v-if="!access">
            <NotFound />
        </div>
        <div v-else>
            <div class="pb-2 mb-3 border-bottom">
                <h2>Apply Comp Off</h2>
            </div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active">
                        <router-link :to="{name: 'comp-offs'}">
                            <span>My Comp Offs</span>
                        </router-link>
                    </li>
                    <li class="breadcrumb-item active">Apply</li>
                </ol>
            </nav>
            <br />
            <div class="container-fluid">
                <div v-if="api_error">
                    <b-alert
                        :show="alertDismissCountDown"
                        dismissible
                        variant="danger"
                        @dismissed="alertDismissCountDown=0"
                        @dismiss-count-down="alertCountDownChanged"
                    >{{api_error}}</b-alert>
                </div>
                <div class="col-md-8">
                    <form v-on:submit.prevent="applyCompOff()">
                        <div class="form-group row">
                            <label class="col-md-3">
                                Comp Off Days:
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-form-select
                                    @change="CompOffCredit"
                                    v-model="apply_compoff.comp_off_day"
                                    :options="comp_off_days"
                                ></b-form-select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                            Comp Off Date
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-input-group size="md" class="mb-1em">
                                    <date-pick
                                        v-model="apply_compoff.date"
                                        :inputAttributes="{readonly: true}"
                                    ></date-pick>
                                </b-input-group>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Comp Off Credit:
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-form-select
                                    v-model="apply_compoff.type"
                                    :options="comp_off_credit_types"
                                ></b-form-select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                Reason
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>
                            <div class="col-md-9">
                                <b-form-textarea
                                    id="textarea"
                                    v-model="apply_compoff.reason"
                                    placeholder="Please enter reason"
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>
                            </div>
                        </div>

                        <div class="form-group row" v-if="employee">
                            <label class="col-md-3">
                                Reporting to
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <span><b>{{ this.employee.reporting_to.full_name }} [IES-{{this.employee.reporting_to.id}}]</b></span>
                            </div>
                        </div>

                        <div class="form-group row" style="float:right;">
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    class="btn btn-primary text-right ma-10px"
                                    v-on:click="applyCompOff()"
                                >Submit</button>
                            </div>
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    @click="$router.go(-1)"
                                    class="btn btn-secondary ma-10px"
                                >Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
export default {
    components: {
        NotFound,
        DatePick
    },
    data() {
        return {
            apply_compoff: {
                reason: "",
                date: new Date().toISOString().slice(0, 10),
                type: '',
                comp_off_day: '',
                emp_id: ""
            },
            
            comp_off_credit_types: [
                { 
                    "text": 'Select Comp Off Credit',
                    "value": ''
                }
            ],

            comp_off_days: [
                { 
                    "text": 'Select Comp Off Day',
                    "value": ''
                }
            ],
            reporting_to: "",
            api_error: "",
            access: true,
            loading: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            employee: '',
            my_compoffs: ''
        };
    },
    mounted() {
        this.getEmployee();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        CompOffCredit (event) {
            let my_comp_off = [];
            this.comp_off_credit_types = [{ "text": 'Select Comp Off Credit', "value": '' }];
            if(event == "comp_off_holidays") {
                my_comp_off = this.my_compoffs.comp_off_holidays;
            }
            if(event == "comp_off_week_offs") {
                my_comp_off = this.my_compoffs.comp_off_week_offs;
            }
            if(my_comp_off.includes('leave')) {
                this.comp_off_credit_types.push({
                    "text": "Credit Leave", 
                    "value": "leave"
                })
            }
            if(my_comp_off.includes('pay')) {
                this.comp_off_credit_types.push({
                    "text": "Credit Pay",
                    "value": "pay"
                })
            }
            this.apply_compoff.type = this.comp_off_credit_types[0].value;
        },

        getEmployee: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.axios.get(this.$api.get_employees, query).then(response => {
                this.employee = response.data.data;
                this.my_compoffs = this.employee.leave_group.compoff;
                if (this.my_compoffs && this.my_compoffs.comp_off_holidays && this.my_compoffs.comp_off_week_offs && ((this.my_compoffs.comp_off_holidays.length !== 0) || (this.my_compoffs.comp_off_week_offs.length !== 0))){
                    this.apply_compoff.emp_id = this.employee.id;
                    if (this.employee.reporting_to) {
                        this.reporting_to = this.employee.reporting_to.id;
                    }
                    if(this.my_compoffs.comp_off_holidays.includes('leave') ) {
                        this.comp_off_days.push({
                            "text": "Holiday",
                            "value": "comp_off_holidays"
                        })
                    }
                    if(this.my_compoffs.comp_off_week_offs.includes('leave') ) {
                        this.comp_off_days.push({
                            "text": "Week Offs",
                            "value": "comp_off_week_offs"
                        })
                    }
                } else {
                    this.access = false;
                }
                this.loading = false;
                this.updateProgressBar(true);
            })
            .catch(err => {
                this.loading = false;
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        applyCompOff: function() {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            this.axios.post(this.$api.apply_compoffs, this.apply_compoff, query).then(() => {
                this.updateProgressBar(true);
                this.$router.push({name: 'comp-offs'});
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}
</style>
